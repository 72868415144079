const LINKS = [
    {
        title: 'Dashboard',
        icon: 'table',
        pages: [
            {
                title: 'Club summary',
                path: '/'
            },
            {
                title: 'Game summary',
                path: '/game-summary'
            },
            {
                title: 'Championships',
                path: '/championships'
            },
            {
                title: 'League overview',
                path: '/league-overview'
            },
            {
                title: 'Fans community',
                path: '/fans-community'
            },
            {
                title: 'Statistics',
                path: '/statistics'
            },
            {
                title: 'Error page',
                path: '/404'
            }
        ]
    },
    {
        title: 'Matches',
        icon: 'calendar',
        pages: [
            {
                title: 'Match summary',
                path: '/match-summary'
            },
            {
                title: 'Match overview',
                path: '/match-overview'
            }
        ]
    },
    {
        title: 'Profile',
        icon: 'users',
        pages: [
            {
                title: 'Login',
                path: '/login'
            },
            {
                title: 'Sign up',
                path: '/sign-up'
            },
            {
                title: 'Player profile',
                path: '/player-profile'
            },
            {
                title: 'Schedule',
                path: '/schedule'
            },
            {
                title: 'Tickets',
                path: '/tickets'
            }
        ]
    },
    {
        title: 'E-commerce',
        icon: 'cart',
        pages: [
            {
                title: 'Football store',
                path: '/football-store'
            },
            {
                title: 'Brand store',
                path: '/brand-store'
            },
            {
                title: 'Product details',
                path: '/product'
            }
        ]
    }
];

export default LINKS